import { SiteMenuItem } from '~/typesManual/menu'

export function getPrimaryMenuItems() {
  const config = useRuntimeConfig()
  return config.public.site?.menu?.primaryMenuItems as SiteMenuItem[]
}

export function getPaperMenuItems() {
  const config = useRuntimeConfig()
  return config.public.site?.menu?.paperMenuItems as SiteMenuItem[]
}

export const getMenuItemUrl = (
  itemUrl: string,
  noPrefix: boolean,
  routePrefix: string
) => {
  return `${noPrefix ? '/' : routePrefix}${itemUrl}`
}
