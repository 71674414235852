<template>
  <div ref="menuToggle" class="z-50">
    <div
      class="cursor-pointer flex flex-col items-center justify-center"
      :class="{
        'px-1 text-blue': isMenuActive,
        'hover:text-gray-600': !isMenuActive,
      }"
      @click="toggleMenu()"
    >
      <div
        class="relative h-5 w-6"
        :class="{
          open: isMenuActive,
        }"
      >
        <span
          class="absolute w-6 h-px transition-all duration-200"
          :class="{
            'transform rotate-45 top-[9px] left-px bg-blue': isMenuActive,
            'top-[3px] left-0 bg-black': !isMenuActive,
          }"
        />
        <span
          class="absolute w-6 h-px bg-black transition-all duration-200"
          :class="{
            'opacity-0': isMenuActive,
            'top-[9px] left-0': !isMenuActive,
          }"
        />
        <span
          class="absolute w-6 h-px transition-all duration-200"
          :class="{
            'transform -rotate-45 top-[9px] left-px bg-blue': isMenuActive,
            'top-[15px] left-0 bg-black': !isMenuActive,
          }"
        />
      </div>
      <span class="list-label mt-2 select-none">
        {{ isMenuActive ? 'Luk' : 'Menu' }}
      </span>
    </div>

    <B2CMenuAltinget :open="isMenuActive" />
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'

const props = withDefaults(
  defineProps<{
    headerVisible: boolean
  }>(),
  { headerVisible: false }
)

const route = useRoute()

const isMenuActive = ref<boolean>(false)
const menuToggle = ref<HTMLDivElement>()

watch(
  () => props.headerVisible === false,
  () => {
    isMenuActive.value = false
  }
)
watch(
  () => route.path,
  () => {
    isMenuActive.value = false
  }
)
const toggleMenu = () => {
  isMenuActive.value = !isMenuActive.value
}
onClickOutside(menuToggle, () => {
  isMenuActive.value = false
})
</script>
