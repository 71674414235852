<template>
  <template v-if="indexStore.currentPaper?.RecordId == 1">
    <NuxtLink
      :to="buttonRoute"
      class="button-l border-b border-gray-300 flex justify-center items-center h-[36px] sm:h-[38px] px-5 bg-blue text-white whitespace-nowrap cursor-pointer sm:rounded-full sm:border sm:bg-blue"
    >
      {{ $t('BuyAbonnement') }}
    </NuxtLink>
  </template>
  <template v-else>
    <NuxtLink
      id="UserCreateProfile"
      :to="{ name: 'subscription' }"
      class="button-l border-b border-gray-300 flex justify-center items-center h-[36px] sm:h-[38px] px-5 bg-blue text-white whitespace-nowrap cursor-pointer sm:rounded-full sm:border sm:bg-blue"
    >
      {{ $t('CreateProfile') }}
    </NuxtLink>
  </template>
</template>

<script setup lang="ts">
const indexStore = useIndexStore()
const buttonRoute = computed(() => {
  return {
    name: indexStore.currentPaperSlug ? 'subscription' : 'products',
  }
})
</script>
