import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "19",
  height: "19",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#2C2CA4",
      d: "M17.719 16.809c.351.351.351.878 0 1.195-.14.176-.352.246-.563.246a.85.85 0 0 1-.633-.246l-4.71-4.711a7.2 7.2 0 0 1-4.536 1.582C3.27 14.875 0 11.605 0 7.563 0 3.555 3.234.25 7.277.25c4.008 0 7.313 3.305 7.313 7.313 0 1.722-.563 3.304-1.582 4.535zM1.687 7.562a5.597 5.597 0 0 0 5.626 5.626 5.62 5.62 0 0 0 5.625-5.626c0-3.093-2.532-5.624-5.626-5.624a5.62 5.62 0 0 0-5.624 5.625"
    }, null, -1)
  ])))
}
export default { render: render }