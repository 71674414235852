<template>
  <div v-if="notificationsStore.shouldShow">
    <LayoutModal @modal-close="onClose">
      <div class="text-center">
        <img
          :src="config.public.site.logo"
          alt="logo"
          class="h-9 mb-8 ml-auto mr-auto"
        />

        <div v-for="group in notificationsStore.grouped" :key="group[0]">
          <h1
            class="!font-normal"
            :class="{
              'headline-xs': !true,
              'text-2xl': true,
            }"
          >
            {{
              $t('notifications.types.' + group[0], {
                siteName: nuxtApp.$config.public.site.name,
              })
            }}
          </h1>
          <div
            :class="{
              'text-base pb-2': true,
              'pb-4': !true,
            }"
          >
            <div
              v-for="(item, key) in group[1]"
              :key="group[0] + key"
              class="p-1"
            >
              <span>{{ item.message }}</span>
            </div>
          </div>
        </div>
        <div v-if="notificationsStore.shouldShowSubscribeButton">
          <template v-if="false">
            <CommonButton
              class="w-full mb-2"
              bg="bg-blue"
              bg-hover="hover:bg-denimblue"
              text="text-white"
              text-hover="text-white"
              border="border-none"
              size="xlarge"
              @click="subscribe"
              >{{ $t('subscribeToLovebakken') }}</CommonButton
            >
          </template>
          <template v-else-if="false">
            <NuxtLink
              :to="{
                name: 'account_subscription',
              }"
            >
              <CommonButton
                class="w-full mb-2"
                bg="bg-blue"
                bg-hover="hover:bg-denimblue"
                text="text-white"
                text-hover="text-white"
                border="border-none"
                size="xlarge"
                @click="notificationsStore.clear()"
                >Gå till Mitt Altinget</CommonButton
              >
            </NuxtLink>
          </template>
          <CommonButton
            size="xlarge"
            class="w-full"
            @click="notificationsStore.clear()"
          >
            {{ $t('noThankYouMaybeLater') }}
          </CommonButton>
        </div>
        <div v-if="shouldShowMissingPassword" class="w-full">
          <div v-if="showAlert">
            <FormAlert type="info" class="py-6">
              {{ $t('AreYouSurePassword') }}
            </FormAlert>
            <div class="mt-6 flex gap-2 justify-center">
              <CommonButton
                bg="bg-blue"
                text="text-white"
                @click="showAlert = false"
              >
                {{ $t('setPassword') }}
              </CommonButton>
              <CommonButton
                bg="bg-grey"
                text="text-blue"
                border="border-gray-500"
                @click="notificationsStore.clear()"
              >
                {{ $t('IAmSure') }}
              </CommonButton>
            </div>
          </div>
          <div v-else>
            <p class="text-center text-base">
              Men lige en sidste ting: Vi mangler en adgangskode til din profil.
            </p>
            <form
              ref="setPasswordForm"
              @submit.prevent="submitForm"
              class="gap-4 flex flex-col w-full mt-6"
            >
              <FormPassword
                v-model="password"
                label="Password"
                placeholder="Password"
                required
              />

              <ul class="body-xs opacity-75 list-disc px-8 text-left">
                <li>
                  {{
                    $t('formErrors.tooShort', {
                      field: $t('Password'),
                      minlength: 8,
                    })
                  }}
                </li>
                <li>{{ $t('passwordRequirements') }}</li>
              </ul>

              <CommonButton
                v-if="true"
                class="block w-full h-14"
                size="xlarge"
                bg="bg-blue"
                text="text-white"
                border="border-none"
                bg-hover="hover:bg-denimblue"
                text-hover="hover:text-white"
                data-testId="is-user-form-submit"
              >
                {{ $t('okLowercaseK') }}
              </CommonButton>
              <CommonButton v-else type="submit" bg="bg-blue" text="text-white">
                {{ $t('okLowercaseK') }}
              </CommonButton>
            </form>
          </div>
        </div>
        <CommonButton
          v-else
          size="xlarge"
          class="w-full mt-4"
          @click="notificationsStore.clear()"
        >
          {{ $t('okLowercaseK') }}
        </CommonButton>
      </div>
    </LayoutModal>
  </div>
</template>

<script setup lang="ts">
const nuxtApp = useNuxtApp()
const config = useRuntimeConfig()

const userStore = useUserStore()
const notificationsStore = useNotificationsStore()
const password = ref('')
const setPasswordForm = ref<HTMLFormElement>()
const LOVEBAKKEN_PAPER_ID = 5
const showAlert = ref(false)
async function subscribe() {
  if (!userStore.user?.email) {
    return
  }

  await nuxtApp.$api.user.subscribeNewsletter(
    userStore.user?.email,
    LOVEBAKKEN_PAPER_ID,
    true
  )
  notificationsStore.clear()
}

const submitForm = async () => {
  showAlert.value = false

  if (setPasswordForm.value?.checkValidity()) {
    // set password
    const result = await userStore.setFirstTimePassword(password.value)

    notificationsStore.clear()
    if (result.status === 'error') {
      notificationsStore.add({
        type: 'error',
        message: nuxtApp.$t('unknownError'),
      })
    }
  }
}

const shouldShowMissingPassword = computed(() => {
  return (
    userStore.missingPassword &&
    notificationsStore.notifications.some((n) => n.isPurchaseComplete)
  )
})

const onClose = () => {
  if (shouldShowMissingPassword.value) {
    showAlert.value = true
    return
  }
  notificationsStore.clear()
}
</script>
