import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "20",
  fill: "none",
  viewBox: "0 0 18 19"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M9 .25c4.957 0 9 4.043 9 9 0 4.992-4.043 9-9 9-4.992 0-9-4.008-9-9 0-4.957 4.008-9 9-9m0 16.875c1.652 0 3.2-.492 4.465-1.371-.14-1.758-1.582-3.129-3.34-3.129h-2.25A3.38 3.38 0 0 0 4.5 15.754c1.266.879 2.813 1.371 4.5 1.371m5.45-2.215c1.476-1.441 2.425-3.445 2.425-5.66 0-4.324-3.55-7.875-7.875-7.875-4.36 0-7.875 3.55-7.875 7.875 0 2.215.914 4.219 2.39 5.66.493-1.933 2.25-3.41 4.36-3.41h2.25c2.074 0 3.832 1.477 4.324 3.41M9 4.75a2.82 2.82 0 0 1 2.813 2.813A2.8 2.8 0 0 1 9 10.375a2.777 2.777 0 0 1-2.812-2.812A2.8 2.8 0 0 1 9 4.75m0 4.5c.914 0 1.688-.738 1.688-1.687 0-.915-.774-1.688-1.688-1.688-.95 0-1.687.773-1.687 1.688 0 .949.738 1.687 1.687 1.687"
    }, null, -1)
  ])))
}
export default { render: render }