<template>
  <div class="flex items-center" ref="nisheModal">
    <div class="cursor-pointer z-20" @click="nishemediaToggle">
      <span
        class="flex flex-col list-label items-center justify-center !font-medium select-none"
        :class="{ 'text-blue': isMenuOpen, 'hover:text-gray-600': !isMenuOpen }"
      >
        <NichemediaIcon class="mb-2" />
        <span> {{ $t('Papers') }} </span>
      </span>
    </div>

    <div
      :class="{
        'opacity-100 translate-y-10': isMenuOpen,
        'opacity-0 translate-y-12 pointer-events-none': !isMenuOpen,
      }"
      class="top-[13px] md:top-7 right-0 md:right-28 overflow-y-scroll custom-scrollbar z-10 w-full md:w-80 h-[95vh] md:h-[80vh] py-5 absolute bg-white shadow transition-all duration-200 transform"
    >
      <NuxtLink
        :to="{ name: 'papers' }"
        class="list-label opacity-50 px-5"
        @click="nishemediaToggle"
        >{{ $t('Papers') }}</NuxtLink
      >
      <div class="flex flex-col mt-4">
        <template v-for="paper in papers" :key="paper.RecordId">
          <NuxtLink
            :to="{ name: 'paper', params: { paper: paper.Url } }"
            class="list-title-xxxs !font-medium flex py-3 px-5 hover:bg-gray-100"
            :class="{
              'text-gray-500 pointer-events-none':
                $route.params.paper === paper.Url,
            }"
            @click="nishemediaToggle"
          >
            <img
              src="/A_Logomark.svg"
              alt="Altinget logo"
              class="w-4 h-4 mr-2"
            />
            {{ paper.Name }}
          </NuxtLink>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import NichemediaIcon from '@/assets/icons/icon-nichemedia.svg?component'
import { onClickOutside } from '@vueuse/core'
// Properties
const props = withDefaults(
  defineProps<{
    headerVisible: boolean
  }>(),
  { headerVisible: false }
)

// Composables
const indexStore = useIndexStore()
// Refs
const isMenuOpen = ref(false)
const nisheModal = ref<HTMLDivElement>()
// Computed
const papers = computed(() =>
  indexStore.papers.filter(
    (paper) => paper.RecordId !== 1 && paper.Status !== 0
  )
)
// Watchers
watch(
  () => props.headerVisible,
  (newVal) => {
    if (!newVal) {
      isMenuOpen.value = false
    }
  }
)

// Methods
const nishemediaToggle = () => {
  isMenuOpen.value = !isMenuOpen.value
}
// Calls
onClickOutside(nisheModal, () => {
  isMenuOpen.value = false
})
</script>
<style lang="scss" scoped>
.custom-scrollbar::-webkit-scrollbar {
  @apply w-1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-gray-400;
}
</style>
