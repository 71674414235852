<template>
  <div class="h-px print:!hidden" ref="pinner" />
  <header
    class="sticky top-0 bg-white z-[9999] print:!hidden transition-transform duration-500"
    :class="{
      'translate-y-0': headerVisible,
      '-translate-y-full': !headerVisible,
    }"
    ref="header"
  >
    <div class="py-3 md:py-6 border-b border-gray-300">
      <div
        class="container relative flex flex-row items-center justify-between"
      >
        <!-- Logo -->
        <div class="z-50 flex">
          <NuxtLink
            :to="{ name: 'frontpage' }"
            class="flex items-center shrink-0"
          >
            <div v-if="isPaperRoute" class="hidden md:block md:w-10">
              <CommonFontAwesomeIcon
                class="text-lg !align-middle ml-2 mr-4"
                icon="fa-regular fa-arrow-left"
              />
            </div>

            <div
              class="h-[24px] md:h-[40px] origin-left inline-flex relative transform-gpu transition-all duration-300"
            >
              <img src="/A_Logo.svg" alt="Altinget logo" />
            </div>
          </NuxtLink>
          <!-- Primary menu. Hide on mobile and paper pages -->
          <ul
            v-if="!isPaperRoute"
            class="hidden xl:flex items-center gap-5 ml-6"
          >
            <template v-for="item in primaryMenuItems" :key="item.label">
              <li>
                <NuxtLink
                  :to="`${getMenuItemUrl(nuxtApp.$te(item.url) ? nuxtApp.$t(item.url) : item.url, item.noPrefix as boolean, routePrefix)}`"
                  class="font-sans text-lg hover:text-blue"
                  active-class="text-blue"
                >
                  {{ $t(item.label) }}
                </NuxtLink>
              </li>
            </template>
          </ul>
        </div>

        <B2CBuyButton
          class="hidden sm:flex ml-auto mr-6"
          v-if="displayBuyButton"
        />
        <div class="flex items-center justify-center gap-x-4 md:gap-x-5">
          <B2CNichemedia :headerVisible="headerVisible" />

          <B2CLogin :headerVisible="headerVisible" />

          <B2CMenuToggle :headerVisible="headerVisible" />
        </div>
      </div>
    </div>
    <B2CBuyButton class="sm:hidden" v-if="displayBuyButton" />
    <div v-if="isPaperRoute" class="bg-[#F3F5F7] z-[15] print:!hidden">
      <div class="container">
        <div
          class="flex flex-col md:flex-row md:items-center h-[96px] md:h-[74px]"
        >
          <NuxtLink
            v-if="indexStore.currentPaperSlug"
            :to="{
              name: 'paper',
              params: { paper: indexStore.currentPaperSlug },
            }"
            class="flex items-center font-bold pt-4 pb-1 md:py-6 text-blue text-2xl font-sans gap-4"
          >
            <img
              src="/A_Logomark.svg"
              alt="Altinget logo"
              class="aspect-square w-6"
            />
            <span>{{ paperName }}</span>
          </NuxtLink>
          <ul class="flex flex-column md:flex-row md:ml-6 h-full gap-5">
            <template v-for="item in paperMenuItems" :key="item.label">
              <li class="h-full relative">
                <NuxtLink
                  :to="`${getMenuItemUrl(nuxtApp.$te(item.url) ? nuxtApp.$t(item.url) : item.url, item.noPrefix as boolean, routePrefix)}`"
                  class="font-sans text-sm md:text-lg h-full flex items-center after:content[''] after:absolute after:bottom-0 after:w-full after:h-0.5 after:bg-transparent hover:after:bg-blue"
                  active-class="after:bg-blue"
                >
                  {{ $t(item.label) }}
                </NuxtLink>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
// Composables
const nuxtApp = useNuxtApp()
const indexStore = useIndexStore()
const route = useRoute()
const userStore = useUserStore()

// Refs
const pinner = ref<HTMLElement>()
const headerVisible = ref(true)

// Variables
const primaryMenuItems = getPrimaryMenuItems()
const paperMenuItems = getPaperMenuItems()
let lastScrollPosition = 0

// Computed
const routePrefix = computed(() => {
  return route.params.paper ? `/${route.params.paper}/` : '/'
})
const paperName = computed(() => {
  // There is a bug in vue if we don't make this a computed value
  return indexStore.currentPaper?.Name ?? ''
})
const isPaperRoute = computed(() => {
  return indexStore.currentPaper && indexStore.currentPaper.RecordId !== 1
})
const displayBuyButton = computed(() => {
  return (
    !['products', 'subscription'].includes(route.name as string) &&
    (!userStore.user ||
      !userStore.hasSubscription(
        indexStore.currentPaper ? indexStore.currentPaper.RecordId : 1
      ))
  )
})

// Methods
const handleScroll = () => {
  const currentScrollPosition =
    window.scrollY || document.documentElement.scrollTop
  headerVisible.value =
    currentScrollPosition < lastScrollPosition ||
    currentScrollPosition <= (isPaperRoute ? 140 : 0) // Hide header not immediately on scroll down, but after 140px - header height on paper pages
  lastScrollPosition = currentScrollPosition
}

// Lifecycle
onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>
